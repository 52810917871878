import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    },
    mutations: {
    },
    actions: {
    },
    getters: {
        isMobile() {
            return store.state.isMobile;
        },
    }
});

export default store;